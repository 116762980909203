import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Header from './header'
import Footer from './footer'
import { LayoutStyle } from './style'
import { Helmet } from 'react-helmet'
import { ReactNode } from 'react'

type LayoutProps = {
  children: ReactNode
  helmetTitle?: string
  helmetDescription?: string
  helmetImage?: string
  helmetUrl?: string
}

const isAllowedChatWoot = process.env.GATSBY_ALLOW_CHATWOOT === 'true'

const isAllowedLeadFeeder = process.env.GATSBY_ALLOW_LEADFEEDER === 'true'

const isAllowedPlausible = process.env.GATSBY_ALLOW_PLAUSIBLE === 'true'

const leadFeederScript = ` (function(ss,ex){ window.ldfdr=window.ldfdr||function(){(ldfdr._q=ldfdr._q||[]).push([].slice.call(arguments));}; (function(d,s){ fs=d.getElementsByTagName(s)[0]; function ce(src){ var cs=d.createElement(s); cs.src=src; cs.async=1; fs.parentNode.insertBefore(cs,fs); }; ce('https://sc.lfeeder.com/lftracker_v1_'+ss+(ex?'_'+ex:'')+'.js'); })(document,'script'); })('bElvO73yvApaZMqj');`

const chatWootScript = `(function(d,t) {
  var BASE_URL='${process.env.GATSBY_CHATWOOT_BASE_URL}';
  var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
  g.src=BASE_URL+"/packs/js/sdk.js";
  g.defer = true;
  g.async = true;
  s.parentNode.insertBefore(g,s);
  g.onload=function(){
    window.chatwootSDK.run({
      websiteToken: '${process.env.GATSBY_CHATWOOT_WEBSITE_TOKEN}',
      baseUrl: BASE_URL
    })
  }
})(document,"script");`

const Layout = ({
  children,
  helmetTitle,
  helmetDescription,
  helmetImage,
  helmetUrl,
}: LayoutProps) => {
  const data = useStaticQuery(graphql`
    {
      prismicLayout {
        data {
          ...logo
          ...footer
          ...navbar
        }
      }
    }
  `)

  const logo = data.prismicLayout.data.logo
  const navbarData = data.prismicLayout.data.nav

  const footerData = [
    {
      copyright: data.prismicLayout.data.copyright,
      company_description: data.prismicLayout.data.footer_description,
    },
    {
      links: data.prismicLayout.data.footer_links,
    },
  ]
  return (
    <>
      <LayoutStyle>
        <Helmet>
          <html lang="en" />
          <title>{helmetTitle}</title>
          {/* Primary Meta Tags */}
          <title>{helmetTitle}</title>
          <meta name="title" content={helmetTitle} />
          <meta name="description" content={helmetDescription} />
          <meta property="og:site_name" content="Cryptr" />
          <meta name="theme-color" content="#5330AC"></meta>
          {/* Open Graph / Facebook */}
          <meta property="og:type" content="website" />
          <meta property="og:url" content={helmetUrl} />
          <meta property="og:title" content={helmetTitle} />
          <meta property="og:description" content={helmetDescription} />
          <meta property="og:image" content={helmetImage} />

          {/* Twitter */}
          <meta property="twitter:site" content="@GetCryptr" />
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content={helmetUrl} />
          <meta property="twitter:title" content={helmetTitle} />
          <meta property="twitter:description" content={helmetDescription} />
          <meta property="twitter:image" content={helmetImage} />

          {/* Favicon */}
          <link
            rel="apple-touch-icon-precomposed"
            sizes="57x57"
            href="apple-touch-icon-57x57.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="114x114"
            href="apple-touch-icon-114x114.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="72x72"
            href="apple-touch-icon-72x72.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="144x144"
            href="apple-touch-icon-144x144.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="60x60"
            href="apple-touch-icon-60x60.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="120x120"
            href="apple-touch-icon-120x120.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="76x76"
            href="apple-touch-icon-76x76.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="152x152"
            href="apple-touch-icon-152x152.png"
          />
          <link rel="icon" type="image/png" href="favicon-196x196.png" sizes="196x196" />
          <link rel="icon" type="image/png" href="favicon-96x96.png" sizes="96x96" />
          <link rel="icon" type="image/png" href="favicon-32x32.png" sizes="32x32" />
          <link rel="icon" type="image/png" href="favicon-16x16.png" sizes="16x16" />
          <link rel="icon" type="image/png" href="favicon-128.png" sizes="128x128" />
          <meta name="application-name" content="&nbsp;Cryptr" />
          <meta name="msapplication-TileColor" content="#FFFFFF" />
          <meta name="msapplication-TileImage" content="mstile-144x144.png" />
          <meta name="msapplication-square70x70logo" content="mstile-70x70.png" />
          <meta name="msapplication-square150x150logo" content="mstile-150x150.png" />
          <meta name="msapplication-wide310x150logo" content="mstile-310x150.png" />
          <meta name="msapplication-square310x310logo" content="mstile-310x310.png" />

          <link
            href="https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;700&display=swap"
            rel="stylesheet"
          />
        </Helmet>
        <Header logo={logo} navbarData={navbarData} />
        {children}
        <Footer logo={logo} footerData={footerData} />
        <span
          id="debug-process-env-GATSBY_ALLOW_PLAUSIBLE"
          className={process.env.GATSBY_ALLOW_PLAUSIBLE}
        ></span>
        {isAllowedPlausible && (
          <script
            id="plausible-script"
            defer
            data-domain="cryptr.co"
            src="https://plausible-app.onrender.com/js/plausible.js"
          ></script>
        )}
        {isAllowedChatWoot && (
          <Helmet
            script={[
              {
                type: 'text/javascript',
                innerHTML: chatWootScript,
              },
            ]}
          />
        )}
        {isAllowedLeadFeeder && (
          <Helmet
            script={[
              {
                type: 'text/javascript',
                innerHTML: leadFeederScript,
              },
            ]}
          />
        )}
      </LayoutStyle>
    </>
  )
}

export default Layout
